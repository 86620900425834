
// Content styles
.section-library .centre .block,
.block-text,
.biography {
	line-height: 1.7;
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			padding: 0 0 $paragraph-margin;
			border-bottom: $border;
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $h4;
			font-weight: $font-normal - 100;
			
			@media #{$mobileXL} {
				font-size: $h3 - 0.12rem;
			}
		}
	}

	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;
	}
	
	// New default <ul> lists
	ul {
		@extend %default-ul;
		color: $color-tertiary;
		font-weight: $font-bold - 200;
	}
	
	// New default <ol> lists
	ol {
		@extend %default-ol;

		&.alpha-list {
			list-style-type: lower-alpha;
		}
	}
}

.biography {

	ul {

		li {
			margin-bottom: $block-margin - 2.5rem;
		}
	}
}


.right {

	img {
		@extend %image;
	}
}
