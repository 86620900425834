
// Default .button class
.button {
    @extend %button;
    display: inline-block;
    padding: ($block-padding * 1/3) ($block-padding * 2);

    &:hover {
        @extend %hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }
}

.payments-btn {

    a {
        @extend %button;
        display: inline-block;
        padding: ($block-padding * 1/3) ($block-padding * 2);
        color: $color-white;
        transition: $transition;
        border-radius: $border-radius + 46px;

         &:hover {
            @extend %hover;
        }
    }
}
